.app-body {
  overflow-x: unset;
}
.header-fixed .app-header {
  z-index: 1000;
}
.col-form-label {
  padding-bottom: 0;
  margin-bottom: 0;
}
.miniBadge {
  position: absolute;
  right: 10px;
  margin-top: 2px;
}
.green {
  color: green;
}
.padLeft {
  margin-left: 10px;
}
.form-group {
  margin-bottom: 0.2rem;
}
.cursorMove {
  cursor: all-scroll;
  user-select: none;
}
.card-header .btn {
  margin-top: -0.2rem !important;
  line-height: 1.4 !important;
}
.app-header .nav-item {
  margin-right: 10px;
}
.divider {
  border-bottom: 1px dashed #0077ce;
}
html body .app.flex-row.align-items-center {
  height: 79vh;
}
.app,
app-dashboard,
app-root {
  min-height: 70vh;
}
label {
  font-weight: bold;
}
.app-header {
  padding: 0px 15px;
}
.p-growl {
  z-index: 5050;
}
body .p-dialog .p-dialog-content {
  padding: 0.571em 1.5em;
}
.p-dialog-mask {
  z-index: 1049;
}
.p-dialog {
  z-index: 1050;
}
.hidden {
  display: none;
}
.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: inherit;
}
.ui-table-resizable > .ui-table-wrapper {
  overflow-x: inherit;
}

.ui-table-resizable .ui-table-thead > tr > th,
.ui-table-resizable .ui-table-tfoot > tr > td,
.ui-table-resizable .ui-table-tbody > tr > td {
  overflow: unset;
}
.sidebar .nav-link {
  font-size: small;
}
.widthMax {
  width: 100%;
}
.invalid {
  border-color: #f86c6b;
}
.valid {
  border-color: #4dbd74;
}
.padTop10 {
  padding-top: 10px;
}
.padTop4 {
  padding-top: 4px;
}
.padRightDivPButtons button {
  margin-right: 6px;
}
.aLinkStyle {
  color: white;
  position: relative;
  top: -20%;
}
.sp-1 {
  margin-right: 5px !important;
}
